import { Logger, Log } from "../Logger";
import { NewformaApiClient } from "./NewformaApiClient";
import { LogRfiParams } from "../../models/workflow/rfi/LogRfiParams";
import { LogRfiResponse } from "../../models/workflow/rfi/LogRfiResponse";
import { Request } from "aws-sign-web";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { ApiRequestErrorLevel, ApiRequestErrorWithMessage } from "../../models/ApiRequestErrorWithMessage";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../AnalyticsManager";
import { FileWithId } from "../../models/shared/FileWithId";
import AttachmentDetails = Office.AttachmentDetails;
import { AttachmentDetailsMetadata } from "../../models/AttachmentDetailsMetadata";
import { OutlookApiService } from "../OutlookApiService";
import { FileUploadApiService } from "./FileUploadApiService";
import v4 = require("uuid/v4");
import { ForwardedRfi, RfiResponse } from "../../models/workflow/rfi/RfiResponse";
import { ProjectKeywordsResponse } from "../../models/ProjectKeywordsResponse";
import { WorkflowActionResponse } from "../../models/workflow/WorkflowActionResponse";
import { RfiReviewResponseRequestParams } from "../../models/workflow/rfi/RfiReviewResponseRequestParams";
import { RfiDetailsResponse } from "../../models/workflow/rfi/RfiDetailsResponse";
import { WorkflowActionType } from "../../models/workflow/WorkflowActionType";
import { EmailApiService } from "./EmailApiService";
import { NrnServiceWrapper } from "../NrnServiceWrapper";
import { PostEmailFilingResponse } from "../../models/PostEmailFilingResponse";
import { AttachmentItem } from "../../models/shared/AttachmentList";
import { mapAttachmentsMetadataToAttachment } from "../../helpers/SendAndFile/AttachmentHelpers";
import { LogForwardRfiRequest } from "../../models/workflow/rfi/ForwardRfiRequest";
import { LogForwardRfiResponse } from "../../models/workflow/rfi/ForwardRfiResponse";

export class RfiApiService {
    componentName = "RfiApiService";

    constructor(
        private logger: Logger,
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private analyticsManager: AnalyticsManager,
        private outlookApiService: OutlookApiService,
        private fileUploadApiService: FileUploadApiService,
        private emailApiService: EmailApiService,
        private nrnServiceWrapper: NrnServiceWrapper
    ) {}

    async fileEmailAsRfi(
        projectNrn: string,
        params: LogRfiParams,
        messageNrn: string,
        attachments: (AttachmentDetails | FileWithId)[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isCloudProject: boolean,
        isLastModifiedDateSupported: boolean,
        isForwardedRfi: boolean
    ): Promise<void> {
        this.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, AnalyticsActionType.FileEmailAsRfi);
        if (!!params.number) {
            this.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, AnalyticsActionType.RfiNumber);
        }

        let attachmentsDetails: AttachmentDetailsMetadata[] = [];
        Log.info(`${this.componentName}logEmailAsSubmittalReviewerResponse`);
        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            params.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);
        }

        let response: LogRfiResponse;

        try {
            response = await this.logRfi(projectNrn, params);
        } catch (error) {
            this.logger.error("RfiApiService. There was an error filing email as RFI", error);

            if ((error as any).status === 409) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    "RFI.ERRORS.NUMBER_CONFLICT",
                    (error as any).status
                );
            }

            throw new ApiRequestErrorWithMessage(
                (error as any).message,
                ApiRequestErrorLevel.ERROR,
                isForwardedRfi ? "RFI.FORWARD_RFI.CREATE_FAILED" : "RFI.ERRORS.LOG_RFI_FAILED_GENERIC",
                (error as any).status
            );
        }

        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "RFI.ERRORS.FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }

        // if there are no attachments, then call forward rfi endpoint
        if (attachments.length === 0 && params.forwardRfiData) {
            await this.logEmailAsForwardRfi(
                params.forwardRfiData,
                projectNrn,
                response.nrn,
                attachments,
                messageNrn,
                isLastModifiedDateSupported
            );
        }

        const filingProjectNrn = isCloudProject ? projectNrn : response.nrn;
        await this.fileEmailToProject(filingProjectNrn, messageNrn);
    }

    async logEmailAsForwardRfi(
        forwardRfi: LogForwardRfiRequest,
        projectNrn: string,
        rfiNrn: string,
        attachments: AttachmentItem[],
        messageNrn: string,
        isLastModifiedDateSupported: boolean
    ): Promise<LogForwardRfiResponse> {
        let attachmentsDetails: AttachmentDetailsMetadata[] = [];
        let forwardResponse;
        let forwardSuccess = false;
        const maxRetries = 3;
        let attempt = 0;
        const retryDelay = 5000;

        function delay(ms: number) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }

        const forwardError: string | null = null;
        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            forwardRfi.attachments = mapAttachmentsMetadataToAttachment(
                attachmentsDetails,
                isLastModifiedDateSupported
            );
        }
        while (attempt < maxRetries && !forwardSuccess) {
            try {
                attempt++;
                forwardResponse = await this.logForwardRfi(forwardRfi, projectNrn, rfiNrn);
                forwardSuccess = true;
            } catch (error) {
                this.logger.error(`RfiApiService - forward RFI attempt ${attempt}. Logging a forwarded RFI`, error);
                if (attempt >= maxRetries) {
                    forwardSuccess = false;
                    const handledErrors: { [index: string]: string } = {
                        403: "RFI.ERRORS.PERMISSIONS_INSUFFICIENT",
                        409: "RFI.ERRORS.NUMBER_CONFLICT",
                        500: "RFI.ERRORS.UNEXPECTED_ERROR",
                    };

                    const errorCode = (error as any).response?.status || 500;
                    const errorMessage = handledErrors[errorCode] || "RFI.ERRORS.UNEXPECTED_ERROR";

                    throw new Error(errorMessage);
                }
                await delay(retryDelay);
            }
        }
        const filingProjectNrn = rfiNrn;
        await this.fileEmailToProject(filingProjectNrn, messageNrn);
        return { forwardSuccess, forwardError };
    }

    async logEmailAsRfiReviewResponse(
        projectNrn: string,
        messageNrn: string,
        rfiNrn: string,
        params: RfiReviewResponseRequestParams,
        attachments: AttachmentItem[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isLastModifiedDateSupported: boolean
    ): Promise<WorkflowActionResponse> {
        this.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.FileEmailAsRfiReviewResponse
        );
        Log.info(`logEmailAsRfiReviewResponse`, "red");
        const attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
            attachments,
            isLastModifiedDateSupported
        );
        params.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);

        let response: WorkflowActionResponse;
        try {
            response = await this.logRfiReviewerResponse(params, projectNrn, rfiNrn);
        } catch (error) {
            Log.error("RfiApiService. Logging a RFI Reviewer Response", error);

            const handledErrors: { [index: string]: string } = {
                403: "RFI.RESPONSE.UNAUTHORIZED_ERROR",
                409: "RFI.RESPONSE.FAILED_CONFLICT",
                500: "RFI.RESPONSE.FAILED_GENERIC",
            };

            const messageToDisplay = handledErrors[(error as any).status];
            if (messageToDisplay) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    messageToDisplay,
                    (error as any).status
                );
            }

            throw error;
        }

        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "RFI.RESPONSE.FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }
        const project = this.nrnServiceWrapper.isCloudProject(projectNrn) ? projectNrn : rfiNrn;
        await this.fileEmailToProject(project, messageNrn);

        return response;
    }

    async getForwardedRfis(projectNrn: string): Promise<ForwardedRfi[]> {
        const isCloudProject = this.nrnServiceWrapper.isCloudProject(projectNrn);
        const filter = isCloudProject ? "forwarded" : undefined;

        const result = await this.getRfis(projectNrn, filter, undefined);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getRfis(projectNrn, filter, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        let forwardedRfis = [];

        if (isCloudProject) {
            forwardedRfis = result.items;
        } else {
            forwardedRfis = result.items.filter((rfi) => rfi.status.type === "forwarded" || rfi.status.type === "open");
        }
        return forwardedRfis;
    }

    async getRfiDetails(rfiNrn: string, projectNrn: string): Promise<RfiDetailsResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${encodeURIComponent(
            projectNrn
        )}/rfis/${encodeURIComponent(rfiNrn)}`;

        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info("Fetching RFI details");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getRfiActionKeywords(projectNrn: string, actionType: WorkflowActionType): Promise<ProjectKeywordsResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${encodeURIComponent(
            projectNrn
        )}/rfis/workflowactions/${actionType}/keywords`;

        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info(`Retrieving rfi keywords for action: ${actionType}`);
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    private async logRfi(projectNrn: string, params: LogRfiParams): Promise<LogRfiResponse> {
        const options: Request = {
            url: `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${projectNrn}/rfis/log`,
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };
        this.logger.info("Logging a rfi");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private async logForwardRfi(forwardRfi: LogForwardRfiRequest, projectNrn: string, rfiNrn: string): Promise<any> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${encodeURIComponent(
            projectNrn
        )}/rfis/${encodeURIComponent(rfiNrn)}/${WorkflowActionType.Forward.toLowerCase()}`;

        const options: Request = {
            url: url,
            method: "PUT",
            body: JSON.stringify(forwardRfi),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info("Logging a FORWARDED RFI");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private async logRfiReviewerResponse(
        reviewerResponse: RfiReviewResponseRequestParams,
        projectNrn: string,
        rfiNrn: string
    ): Promise<WorkflowActionResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${encodeURIComponent(
            projectNrn
        )}/rfis/${encodeURIComponent(rfiNrn)}/${WorkflowActionType.ReviewResponse.toLocaleLowerCase()}`;
        const options: Request = {
            url: url,
            method: "PUT",
            body: JSON.stringify(reviewerResponse),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info("Logging a rfi reviewer response");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private async fileEmailToProject(projectNrn: string, messageNrn: string): Promise<PostEmailFilingResponse> {
        this.logger.info("Logging an rfi - Filing email");

        try {
            return await this.emailApiService.fileToProject(projectNrn, messageNrn);
        } catch (error) {
            this.logger.error("RfiApiService. Logging an rfi - Filing email", error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }

            throw new ApiRequestErrorWithMessage(
                (error as any).message,
                ApiRequestErrorLevel.ERROR,
                "RFI.ERRORS.FILING_EMAIL_FAILED",
                (error as any).status
            );
        }
    }

    private async getRfis(projectNrn: string, filter: string = "", offsetToken: string = ""): Promise<RfiResponse> {
        const maxPageSize = 50;
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${projectNrn}/rfis?status=${filter}&maxSize=${maxPageSize}&offsetToken=${offsetToken}`;
        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info("Retrieving rfis");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }
}
