import { OfficeWrapper } from "./OfficeWrapper";
import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { MsGraphGetExpandedEmailAttachmentResponse } from "../models/MsGraphGetExpandedEmailAttachmentResponse";
import { EmailAttachmentExtensionAndMime } from "../models/EmailAttachmentExtensionAndMime";
import { Logger, Log } from "./Logger";
import { WindowWrapper } from "./WindowWrapper";
import { AttachmentDetailsMetadata } from "../models/AttachmentDetailsMetadata";

import { FileWithId } from "../models/shared/FileWithId";
import AttachmentDetails = Office.AttachmentDetails;

type AttachmentItem = AttachmentDetails | FileWithId;

export enum EmailAttachmentOdataType {
    Contact = "#Microsoft.OutlookServices.Contact",
    Event = "#Microsoft.OutlookServices.Event",
    Message = "#Microsoft.OutlookServices.Message",
    Task = "#Microsoft.OutlookServices.Task",
}

export enum OutlookItemMimeType {
    email = "message/rfc822",
    contact = "text/vcard",
    event = "text/calendar",
}

export class OutlookApiService {
    private readonly valueParam = "/$value";
    private readonly messagesUriComponent = "/v2.0/me/messages";
    private readonly retryAfterHeaderKey = "Retry-After";
    private readonly defaultRetryTimeInSeconds = 1;
    private readonly maxRetryAttempts = 5;
    constructor(
        private officeWrapper: OfficeWrapper,
        private requestWrapper: HttpRequestWrapper,
        private windowWrapper: WindowWrapper,
        private logger: Logger
    ) {}

    // https://docs.microsoft.com/en-us/previous-versions/office/office-365-api/api/version-2.0/mail-rest-operations#GetAttachments
    async getEmailAttachmentBytes(
        attachmentId: string,
        attachmentType: Office.MailboxEnums.AttachmentType | string,
        attachmentMimeType: string
    ): Promise<Blob> {
        this.logger.info("Retrieving email attachment bytes from Microsoft Graph");
        const authorizationToken = await this.officeWrapper.getCallbackTokenWithRetry();

        const itemRestId = this.officeWrapper.convertToRestId(this.officeWrapper.currentContextItem);
        const urlSuffix = attachmentType === Office.MailboxEnums.AttachmentType.File ? "" : this.valueParam;

        const url = `${this.officeWrapper.getRestUrl()}${
            this.messagesUriComponent
        }/${itemRestId}/attachments/${this.convertToUnifiedId(attachmentId)}${urlSuffix}`;
        const headers = { Authorization: `Bearer ${authorizationToken}` };

        const result = await this.makeRequestWithRetry(() =>
            this.requestWrapper.get(url, undefined, headers, undefined, undefined)
        );

        return this.getBlobFromBase64EncodedByteString(result.ContentBytes || result, attachmentMimeType);
    }

    async getExtensionAndMimeForItemAttachment(
        attachmentId: string,
        attachmentType: Office.MailboxEnums.AttachmentType | string, // file,item,cloud
        contentType: string | undefined
    ): Promise<EmailAttachmentExtensionAndMime> {
        if (attachmentType !== Office.MailboxEnums.AttachmentType.Item) {
            return { extension: "", mimeType: contentType || "" }; // its an inline email attachment
        }

        const expandedAttachmentDetails = await this.getExpandedEmailAttachmentDetails(attachmentId);

        const attachmentItemType = expandedAttachmentDetails.Item["@odata.type"];

        const extension = this.getExtension(attachmentItemType);
        const mimeType = this.getMimeType(attachmentItemType);

        return { extension: extension, mimeType: mimeType };
    }

    getMimeTypeFromBytes(byteArray: Uint8Array): string | null {
        // short-circuit test... if there is not enough data then exit
        if (byteArray.length < 8) {
            return null;
        }

        const len = byteArray.length;

        // traditional checks
        const signatures: any = {
            "image/png": [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a],
            "image/jpeg": [0xff, 0xd8, 0xff],
            "image/gif": [0x47, 0x49, 0x46, 0x38],
            "image/bmp": [0x42, 0x4d],
            "image/webp": [0x52, 0x49, 0x46, 0x46, 0x57, 0x45, 0x42, 0x50],
            "image/svg+xml": [0x3c, 0x73, 0x76, 0x67], // SVG - check for "<svg"
            "image/tiff": [0x49, 0x49, 0x2a, 0x00], // Little-endian TIFF (most common)
            "image/tiff@": [0x4d, 0x4d, 0x00, 0x2a], // Big-endian TIFF

            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [0x50, 0x4b, 0x03, 0x04], // .docx
            "application/pdf": [0x25, 0x50, 0x44, 0x46],
            "image/vnd.dwg": [0x41, 0x43, 0x31, 0x30], // .dwg
            "application/zip": [0x50, 0x4b, 0x03, 0x04], // .zip
            "application/x-7z-compressed": [0x37, 0x7a, 0xbc, 0xaf, 0x27, 0x1c], // .7z
            "application/rtf": [0x7b, 0x5c, 0x72, 0x74, 0x66, 0x31], // RTF ("{\rtf1")
            "application/vnd.autodesk.designwebformat": [0x41, 0x73, 0x43, 0x44], // DWF ("AsCD")
            "application/dxf": [0x44, 0x58, 0x46, 0x0a], // ASCII DXF ("DXF\n") - Adjust newline as needed ? meaning remove?
            "application/dwf": [0x41, 0x73, 0x43, 0x44],

            "font/woff": [0x77, 0x4f, 0x46, 0x46], // font woff file
        };

        for (const mimeType in signatures) {
            if (mimeType) {
                const signature = signatures[mimeType];
                const sigLen = signature.length;

                if (len >= sigLen && byteArray.slice(0, sigLen).every((val, i) => val === signature[i])) {
                    let k = mimeType.indexOf("@");
                    if (k < 0) {
                        k = mimeType.length;
                    }
                    return mimeType.slice(0, k);
                }
            }
        }

        return null;
    }

    base64ToBytes(base64: any) {
        try {
            const buffer = Buffer.from(base64, "base64"); // Decode Base64 using Buffer
            return new Uint8Array(buffer.buffer, buffer.byteOffset, buffer.length); // Create Uint8Array view
        } catch (error) {
            console.error("Invalid Base64 string:", error);
            return null;
        }
    }

    async getAttachmentContentDirect(attachmentId: any): Promise<Blob> {
        return new Promise<Blob>((resolve, reject) => {
            this.officeWrapper.currentContextItem.getAttachmentContentAsync(attachmentId, {}, (asyncResult: any) => {
                const theFormat = asyncResult.value?.format ?? "";
                // two of these types are not enumerated in the older Office-js
                // const {Base64,Eml} = Office.MailboxEnums.AttachmentContentFormat;
                enum ContentFormat {
                    Base64 = "base64",
                    Eml = "eml",
                    Text = "text",
                    Html = "html",
                } // add the missing enums
                let type = "";
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    const content = asyncResult.value.content;
                    Log.info("Success: Attachment Content Retrieved ");

                    if (theFormat === ContentFormat.Base64) {
                        Log.info(`Base64 Content: Size ${content.length}`);

                        try {
                            // Convert Base64 to Bytes
                            const start = Date.now();

                            const byteCharacters = this.base64ToBytes(content);
                            if (byteCharacters) {
                                const chunkSize = 0x10000;
                                const byteArrays: any = [];
                                for (let offset = 0; offset < byteCharacters.length; offset += chunkSize) {
                                    const slice = byteCharacters.slice(offset, offset + chunkSize);

                                    const byteNumbers = new Array(slice.length);
                                    for (let i = 0; i < slice.length; i++) {
                                        byteNumbers[i] = slice[i];
                                    }

                                    const byteArray = new Uint8Array(byteNumbers);
                                    byteArrays.push(byteArray);
                                }
                                type = asyncResult.value.contentType ?? this.getMimeTypeFromBytes(byteArrays[0]) ?? "";

                                const blob = new Blob(byteArrays, { type });

                                resolve(blob);
                            } else {
                                Log.info(`Base 64 data is empty`);
                            }
                            Log.info(`Blob <${type}>: Load Time:${Date.now() - start} ms`);
                        } catch (error) {
                            Log.error("Error converting Base64 to Blob:", error);
                            reject(undefined); // Or reject with the error
                        }
                    } else {
                        if (theFormat === ContentFormat.Text) {
                            type = "text/plain";
                        }
                        if (theFormat === ContentFormat.Eml) {
                            type = "message/x-eml";
                        } // formerly 'text/html' which loses multi parts of an email
                        if (theFormat === ContentFormat.Html) {
                            type = "text/html";
                        }

                        const blob = new Blob([content], { type });

                        resolve(blob);
                    }
                } else {
                    Log.error("Error fetching attachment:", asyncResult.error);
                    reject(undefined);
                }
            });
        });
    }

    isFileWithId(item: AttachmentItem): item is FileWithId {
        const i: any = item;

        return !!i?.lastModifiedDate;
    }

    async processPromises(promises: any) {
        const results = [];
        for (const promise of promises) {
            try {
                const result = await promise; // Wait for the current promise to resolve
                results.push(result);
            } catch (error) {
                console.error("Error processing a promise:", error);
                // Handle the error as needed (e.g., re-throw, continue, break)
                // Example: re-throw to stop processing:
                // throw error;
            }
        }
        return results;
    }

    isFileWithIDType(value: any): boolean {
        return "file" in value;
    }

    async getFileAttachment(
        attachment: AttachmentItem,
        isLastModifiedDateSupported?: boolean
    ): Promise<AttachmentDetailsMetadata | undefined> {
        let noFileNameIndex = 0;
        let result;

        const id = attachment.id;
        const isFile = this.isFileWithIDType(attachment);

        if (!isFile) {
            // this identfies as an internal attachment
            const internal = attachment as AttachmentDetails;
            const aType = internal.attachmentType;
            const cType = internal.contentType;

            const attachmentContent = await this.getAttachmentContentDirect(id);
            const attachmentMetadata = await this.getExtensionAndMimeForItemAttachment(id, aType, cType);
            //
            result = {
                id,
                fileName: `${attachment.name || `_[${noFileNameIndex++}]`}${attachmentMetadata.extension}`,
                file: attachmentContent,
            };

            Log.info("Internal File acquired");
        } else {
            // this identfies as an external attachment
            const external = attachment as FileWithId;
            result = {
                id,
                fileName: external.name,
                file: external.file,
                ...(isLastModifiedDateSupported &&
                    external.lastModifiedDate && { lastModifiedDate: external.lastModifiedDate }),
            };
            Log.info("External File acquired");
        }
        Log.info(`Processing attachment [${result?.fileName ?? ""} ]`);
        return result;
    }

    async getFileAttachmentDetailsMetadata(
        attachments: AttachmentItem[], // this is a composite type, could have a lastModifiedData,
        isLastModifiedDateSupported?: boolean
    ): Promise<AttachmentDetailsMetadata[]> {
        if (attachments?.length === 0) {
            return [];
        }
        let noFileNameIndex = 0;

        Log.info(`Attachment List [${attachments.length}]`);

        const promises = attachments.map(async (attachment: any, index) => {
            Log.info(`Processing attachment [${attachment.name} size ${attachment?.size}]`);
            if (this.isAttachmentDetails(attachment)) {
                Log.info(`data contentType= ${attachment.contentType}`);
                const attachmentMetadata = await this.getExtensionAndMimeForItemAttachment(
                    attachment.id,
                    attachment.attachmentType,
                    attachment.contentType
                );
                Log.info(`data attachmentType= ${attachment.attachmentType}`); // says its a file...
                const attachmentContent = await this.getAttachmentContentDirect(attachment.id);

                return {
                    id: attachment.id,
                    fileName: `${attachment.name || `_[${noFileNameIndex++}]`}${attachmentMetadata.extension}`,
                    file: attachmentContent,
                };
            }

            if (isLastModifiedDateSupported) {
                return {
                    id: attachment.id,
                    fileName: attachment.id,
                    file: attachment.file,
                    lastModifiedDate: new Date(attachment.file.lastModified),
                };
            } else {
                return {
                    id: attachment.id,
                    fileName: attachment.id,
                    file: attachment.file,
                };
            }
        });

        return this.processPromises(promises);
    }

    private async getExpandedEmailAttachmentDetails(
        attachmentId: string
    ): Promise<MsGraphGetExpandedEmailAttachmentResponse> {
        this.logger.info("Retrieving email attachment expanded details from Outlook REST API");
        const authorizationToken = await this.officeWrapper.getCallbackTokenWithRetry();

        const itemRestId = this.officeWrapper.convertToRestId(this.officeWrapper.currentContextItem);
        const url = `${this.officeWrapper.getRestUrl()}${
            this.messagesUriComponent
        }/${itemRestId}/attachments/${this.convertToUnifiedId(
            attachmentId
        )}?$expand=Microsoft.OutlookServices.ItemAttachment/Item`;
        const headers = { Authorization: `Bearer ${authorizationToken}` };

        return this.makeRequestWithRetry(() => this.requestWrapper.get(url, undefined, headers, undefined, undefined));
    }

    private convertToUnifiedId(id: string) {
        return id.replace(/\//g, "-").replace(/\+/g, "_");
    }

    private getExtension(attachmentItemType: string): string {
        switch (attachmentItemType) {
            case EmailAttachmentOdataType.Message:
            case EmailAttachmentOdataType.Task:
                return ".eml";
            case EmailAttachmentOdataType.Contact:
                return ".vcf";
            case EmailAttachmentOdataType.Event:
                return ".ics";
            default: {
                return ".eml";
            }
        }
    }

    private getMimeType(attachmentItemType: EmailAttachmentOdataType) {
        switch (attachmentItemType) {
            case EmailAttachmentOdataType.Message:
            case EmailAttachmentOdataType.Task:
                return OutlookItemMimeType.email;
            case EmailAttachmentOdataType.Contact:
                return OutlookItemMimeType.contact;
            case EmailAttachmentOdataType.Event:
                return OutlookItemMimeType.event;
            default:
                return OutlookItemMimeType.email;
        }
    }

    private async makeRequestWithRetry(request: () => Promise<any>, attemptCount: number = 1): Promise<any> {
        try {
            return await request();
        } catch (error) {
            if (attemptCount === this.maxRetryAttempts) {
                this.logger.error(
                    `OutlookApiService. Max retry attempts (${this.maxRetryAttempts}) reached for request.`,
                    error
                );
                throw error;
            }
            if ((error as any).status && (error as any).status === 429) {
                const retryAfter =
                    ((error as any).getResponseHeader(this.retryAfterHeaderKey) || this.defaultRetryTimeInSeconds) *
                    1000;
                this.logger.error(
                    `OutlookApiService. The request was throttled by the office api on attempt (${attemptCount}). retrying after ${retryAfter}ms... `
                );
                await new Promise((resolve) => {
                    setTimeout(resolve, retryAfter);
                });
                return this.makeRequestWithRetry(request, attemptCount + 1);
            }
        }
    }

    private getBlobFromBase64EncodedByteString(fileBytes: string, mimeType: string): Blob {
        let decodedBytes: string;
        try {
            decodedBytes = this.windowWrapper.base64Decode(fileBytes);
        } catch {
            decodedBytes = fileBytes;
        }
        const binaryLength = decodedBytes.length;
        const blobParts = new Uint8Array(binaryLength);
        for (let i = 0; i < binaryLength; i++) {
            blobParts[i] = decodedBytes.charCodeAt(i);
        }
        return new Blob([blobParts], { type: mimeType });
    }

    private isAttachmentDetails(attachment: AttachmentItem): attachment is AttachmentDetails {
        return !!(attachment as AttachmentDetails).attachmentType;
    }
}
